import Container from '@/components/Container/Container';
import ServiceCard from '@/components/ServiceListing/_components/ServiceCard';
import { getButtonData } from '@/shared/helpers/buttons';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useMicrocopy } from '@/shared/providers/Microcopy/MicrocopyHook';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { ButtonStoryblok, FeaturedArticleStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import { useContext, useRef, useState } from 'react';
import { Keyboard, Scrollbar } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { ArticleItem } from '../ArticleItem/ArticleItem';
import styles from './FeaturedArticleDesktop.module.scss';

const FeaturedArticleDesktop: React.FC<FeaturedArticleStoryblok> = ({ blok }) => {
    const darkTheme = useThemeSection() === 'Dark';
    const hexColor = useContext(ColorContext);
    const articleItems = blok?.articleItems ?? blok;
    const [isScrollbarMoving, setIsScrollbarMoving] = useState<boolean>(false);
    const [btnLink, setBtnLink] = useState(articleItems[0]?.full_slug ?? '/');
    const { microcopy } = useMicrocopy();

    let btn = { blok: getButtonData(microcopy?.featuredArticleButton, darkTheme, btnLink, blok.arrow) };
    const swiperRef = useRef<SwiperRef>(null);
    const [openId, setOpenId] = useState('');
    const spaceBetween = articleItems[0]?.content?.component === 'service' ? 54 : 12;

    return (
        <Container
            blok={blok?.blok}
            code="standard"
            className={{
                root: clsx(styles.slider_outer_container, {
                    [styles.dark]: true,
                    isDark: darkTheme,
                    isScrollbarMoving: isScrollbarMoving,
                }),
            }}
        >
            {articleItems?.length > 1 ? (
                <Swiper
                    modules={[Scrollbar, Keyboard]}
                    slidesPerView={articleItems.length === 1 ? 1 : 1.62}
                    spaceBetween={spaceBetween}
                    onTransitionEnd={swiperCore => {
                        const { realIndex, snapIndex } = swiperCore;
                        if (snapIndex === articleItems.length - 1) {
                            setBtnLink(articleItems[snapIndex].full_slug);
                        } else {
                            setBtnLink(articleItems[realIndex].full_slug);
                        }
                    }}
                    scrollbar={{
                        draggable: true,
                        snapOnRelease: true,
                    }}
                    keyboard={{ enabled: true }}
                    onScrollbarDragStart={() => setIsScrollbarMoving(true)}
                    onScrollbarDragEnd={swiperCore => {
                        const { snapIndex } = swiperCore;
                        setIsScrollbarMoving(false);
                        setBtnLink(articleItems[snapIndex].full_slug);
                    }}
                    ref={swiperRef}
                >
                    {articleItems.map(
                        (item: { default_full_slug: string; content: any; customTitle: string }, index: number) => {
                            if (item?.content?.component === 'service') {
                                return (
                                    <SwiperSlide key={item.content._uid} className={styles.swiper_slide}>
                                        <ServiceCard
                                            element={item.content}
                                            isOpen={openId === item.content._uid}
                                            theme="light"
                                            handleCardSide={() =>
                                                setOpenId(openId === item.content._uid ? '' : item.content._uid)
                                            }
                                            isServicePage={false}
                                            slug={articleItems[index].full_slug ?? articleItems[index].slug}
                                        />
                                    </SwiperSlide>
                                );
                            }
                            return (
                                <SwiperSlide key={item?.content?._uid} className={styles.swiper_slide}>
                                    <ArticleItem
                                        darkTheme={darkTheme}
                                        tag={item?.content?.component}
                                        author={item?.content?.author ? item?.content?.author?.name : ''}
                                        customTitle={item?.content?.customTitle || item?.content?.title || ''}
                                        color={hexColor}
                                        button={btn as any as ButtonStoryblok}
                                        article={item?.content}
                                        slug={item?.default_full_slug}
                                    />
                                </SwiperSlide>
                            );
                        },
                    )}
                </Swiper>
            ) : (
                <ArticleItem
                    darkTheme={darkTheme}
                    tag={articleItems[0]?.content?.component}
                    author={articleItems[0]?.content?.author ? articleItems[0]?.content?.author?.name : ''}
                    customTitle={articleItems[0]?.content?.customTitle || articleItems[0]?.content?.title || ''}
                    color={hexColor}
                    button={btn as any as ButtonStoryblok}
                    article={articleItems[0]}
                    slug={articleItems[0]?.default_full_slug}
                />
            )}
        </Container>
    );
};
export default FeaturedArticleDesktop;
