import { getButtonData } from '@/shared/helpers/buttons';
import { dots } from '@/shared/helpers/dots';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { ButtonStoryblok, FeaturedArticleStoryblok, ServiceStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import { useContext, useState } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArticleItem } from '../ArticleItem/ArticleItem';
import styles from './FeaturedArticleMobile.module.scss';
import { useMicrocopy } from '@/shared/providers/Microcopy/MicrocopyHook';
import ServiceCard from '@/components/ServiceListing/_components/ServiceCard';

const FeaturedArticleMobile: React.FC<FeaturedArticleStoryblok> = ({ blok }) => {
    const darkTheme = useThemeSection() === 'Dark';
    const hexColor = useContext(ColorContext);
    const { microcopy } = useMicrocopy();
    const articleItems = blok?.articleItems;
    const [article, setArticle] = useState(articleItems[0]);
    let btn = {
        blok: getButtonData(microcopy.featuredArticleButton, darkTheme, article?.full_slug || '/', blok.arrow),
        component: 'button',
        _uid: '',
    } as any as ButtonStoryblok;
    const [openId, setOpenId] = useState('');

    return (
        <div {...storyblokEditable(blok)}>
            <div className={styles.slider_outer_container}>
                {articleItems?.length > 1 ? (
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={1}
                        loop={false}
                        centeredSlides={true}
                        spaceBetween={300}
                        className={styles.swiper}
                        style={dots(darkTheme) as any}
                        onSlideChange={swiperCore => {
                            const { activeIndex } = swiperCore;
                            setArticle(articleItems[activeIndex]);
                        }}
                        pagination={{
                            type: 'bullets',
                            clickable: false,
                            dynamicBullets: true,
                        }}
                        followFinger={false}
                    >
                        {articleItems.map(
                            (
                                item: {
                                    default_full_slug: string;
                                    content: FeaturedArticleStoryblok | ServiceStoryblok;
                                    customTitle: string;
                                },
                                index: number,
                            ) => {
                                if (item.content.component === 'service') {
                                    return (
                                        <SwiperSlide key={item.content._uid} className={styles.swiper_slide}>
                                            <ServiceCard
                                                element={item.content}
                                                isOpen={openId === item.content._uid}
                                                theme="light"
                                                handleCardSide={() =>
                                                    setOpenId(openId === item.content._uid ? '' : item.content._uid)
                                                }
                                                isServicePage={false}
                                                slug={articleItems[index].full_slug ?? articleItems[index].slug}
                                            />
                                        </SwiperSlide>
                                    );
                                }
                                return (
                                    <SwiperSlide key={item.content._uid} className={styles.swiper_slide}>
                                        <ArticleItem
                                            darkTheme={darkTheme}
                                            tag={item.content.component}
                                            author={item.content.author ? item.content.author.name : ''}
                                            customTitle={item.content.customTitle || item.content.title || ''}
                                            color={hexColor}
                                            article={article}
                                            slug={item.default_full_slug}
                                            button={btn}
                                        />
                                    </SwiperSlide>
                                );
                            },
                        )}
                    </Swiper>
                ) : (
                    <ArticleItem
                        darkTheme={darkTheme}
                        tag={articleItems[0]?.content?.component}
                        author={articleItems[0]?.content?.author ? articleItems[0]?.content?.author?.name : ''}
                        customTitle={articleItems[0]?.content?.customTitle || articleItems[0]?.content?.title || ''}
                        color={hexColor}
                        article={article}
                        slug={articleItems[0]?.default_full_slug}
                        button={btn}
                    />
                )}
            </div>
        </div>
    );
};
export default FeaturedArticleMobile;
