import { ButtonStoryblok } from '../types/storyblok/storyblok-types';

export function getButtonData(text: string, darkTheme: boolean, slug: string, arrow: boolean): ButtonStoryblok {
    const buttonData: ButtonStoryblok = {
        buttonText: text,
        buttonLink: { linktype: 'story', cached_url: slug, target: '_self' },
        darkTheme: darkTheme,
        gray: false,
        colorArrow: arrow,
        _uid: '',
        component: 'button',
    };
    return buttonData;
}
