export function dots(dark: boolean) {
    if (dark) {
        return {
            '--swiper-pagination-color': 'var(--core60)',
            '--swiper-pagination-bullet-size': '8px',
            '--swiper-pagination-bullet-width': '8px',
            '--swiper-pagination-bullet-height': '8px',
            '--swiper-pagination-bullet-inactive-color': 'var(--core20)',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-pagination-bullet-horizontal-gap': '12px',
            '--swiper-pagination-top:': '50px',
        };
    }
    return {
        '--swiper-pagination-color': 'var(--core40)',
        '--swiper-pagination-bullet-size': '8px',
        '--swiper-pagination-bullet-width': '8px',
        '--swiper-pagination-bullet-height': '8px',
        '--swiper-pagination-bullet-inactive-color': 'var(--core80)',
        '--swiper-pagination-bullet-inactive-opacity': '1',
        '--swiper-pagination-bullet-horizontal-gap': '12px',
        '--swiper-pagination-top:': '50px',
    };
}
