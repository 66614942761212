import Button from '@/components/Buttons/Button';
import { Typography, colorText } from '@/shared';
import { useBreakpoints } from '@/shared/providers/Device';
import { ArticleStoryblok, ButtonStoryblok, ServiceStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import Link from 'next/link';
import { useState } from 'react';
import { cardSubtitle } from '../../helper/subtitle';
import styles from './ArticleItem.module.scss';

type ArticleItemProps = {
    darkTheme: boolean;
    tag: string;
    author: string;
    customTitle: string;
    color: string;
    button?: ButtonStoryblok;
    article: ArticleStoryblok | ServiceStoryblok | any;
    slug: string;
};

export const ArticleItem: React.FC<ArticleItemProps> = ({
    darkTheme,
    tag,
    customTitle,
    author,
    color,
    button,
    slug,
    article,
}) => {
    const tagline = cardSubtitle(author, tag);
    const [hover, setHover] = useState(false);
    const { isXXSmall, isExtraSmall, isSmall } = useBreakpoints();
    const isMobile = isXXSmall || isExtraSmall || isSmall;
    const colorCustomTitle = colorText(color, customTitle, darkTheme);

    const articleBody = (
        <div
            className={clsx(darkTheme ? styles.card_dark : styles.card)}
            onMouseEnter={e => {
                setHover(true);
                e.stopPropagation();
            }}
            onMouseLeave={e => {
                setHover(false);
                e.stopPropagation();
            }}
        >
            {tagline && (
                <Typography.Small className={clsx(darkTheme ? styles.tag_dark : styles.tag)} darkTheme={darkTheme}>
                    {tagline}
                </Typography.Small>
            )}
            <Typography.H3 className={clsx(darkTheme ? styles.title_dark : styles.title)} darkTheme={darkTheme}>
                <span className={styles.spanTitle} dangerouslySetInnerHTML={{ __html: colorCustomTitle }} />
            </Typography.H3>
            <div className={clsx((button && isMobile) || (button && hover) ? styles.btn : styles.hidden)}>
                {button && (
                    <div className={styles.btn}>
                        <Button {...button}></Button>
                    </div>
                )}
            </div>
        </div>
    );

    if (slug) {
        return (
            <Link scroll={true} href={slug}>
                {articleBody}
            </Link>
        );
    } else {
        return articleBody;
    }
};
