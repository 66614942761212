import Container from '@/components/Container/Container';
import { SortByType, getSortedContent } from '@/shared/helpers/sort';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import { ColorContext } from '@/shared/providers/ColorContext';
import { useMicrocopy } from '@/shared/providers/Microcopy/MicrocopyHook';
import { ServiceStoryblok } from '@/shared/types/storyblok/storyblok-types';
import ServiceCard from './ServiceCard';
import styles from './ServiceListing.module.scss';

type ServiceListingCoreProps = {
    content: ServiceStoryblok[];
    sortBy: SortByType;
    startingItems?: number;
};

export const cardSuit = (inputString: string) => {
    const lowerCaseInput = inputString?.toLowerCase();

    if (lowerCaseInput === 'innovation') {
        return 'heart';
    } else if (lowerCaseInput === 'strategy') {
        return 'diamond';
    } else if (lowerCaseInput === 'development') {
        return 'club';
    }
    return 'spade';
};

const ServiceListingCore: React.FC<ServiceListingCoreProps> = props => {
    const { content: originalContent, sortBy, startingItems = 1000 } = props;

    const [content, setContent] = useState(originalContent);

    useEffect(() => {
        if (originalContent && originalContent.length > 0) {
            let sortedContent = [...originalContent];
            if (sortBy) {
                sortedContent = getSortedContent(sortedContent, sortBy);
            }
            setContent(sortedContent);
        }
    }, [sortBy, originalContent]);

    const hexColor = useContext(ColorContext);
    const { microcopy } = useMicrocopy();
    const [shownCount, setShownCount] = useState<number>(startingItems);

    const replaceNumber = (text: string, number: number): string => {
        return text.replace('{number}', String(number));
    };
    const remainingItems = Math.min(1, content?.length - shownCount);

    const [openCardIndex, setOpenCardIndex] = useState<string | null>(null);

    const handleCardSide = (id: string) => {
        setOpenCardIndex(openCardIndex === id ? null : id);
    };

    return (
        <Container code="fullpage" className={{ wrap: clsx(styles.root) }}>
            <div className={styles.wrapper}>
                {content &&
                    content.slice(0, shownCount).map((element: ServiceStoryblok) => {
                        return (
                            <ServiceCard
                                key={element.content._uid}
                                element={element}
                                isOpen={openCardIndex === element.content._uid}
                                handleCardSide={handleCardSide}
                                isServicePage={false}
                            />
                        );
                    })}
            </div>
            {shownCount < content?.length && (
                <div
                    className={styles.button}
                    style={{ borderColor: hexColor }}
                    onClick={() => setShownCount((prevState: number) => prevState + startingItems)}
                >
                    {replaceNumber(microcopy.loadMoreButton, remainingItems)}
                </div>
            )}
        </Container>
    );
};

export default ServiceListingCore;
