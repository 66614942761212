import { getColor } from '@/shared';
import { navigate, pushSlug } from '@/shared/helpers/url';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { ButtonStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import styles from './Button.module.scss';
import Link from 'next/link';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    darkTheme?: boolean;
    color?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
const ColorButton: React.FC<ButtonProps> = ({ children, darkTheme, color, onClick }) => {
    const borderStyle = { border: '2px solid ' + color };
    return (
        <button onClick={onClick} style={borderStyle} className={clsx(darkTheme ? styles.btn_dark : styles.btn)}>
            {children}
        </button>
    );
};
const UniversalButton: React.FC<ButtonProps> = ({ children, onClick }) => {
    return (
        <button onClick={onClick} className={styles.universal}>
            {children}
            <Icon name="arrow_forward" />
        </button>
    );
};
const GrayButton: React.FC<ButtonProps> = ({ children, onClick }) => {
    return (
        <button onClick={onClick} className={styles.universal}>
            {children}
        </button>
    );
};

const ColorArrowButton: React.FC<ButtonProps> = ({ children, darkTheme, color, onClick }) => {
    const borderStyle = { border: '2px solid ' + color };
    return (
        <button onClick={onClick} style={borderStyle} className={clsx(darkTheme ? styles.cta_dark : styles.cta)}>
            {children}
            <Icon name="arrow_forward" />
        </button>
    );
};
const Button = (data: ButtonStoryblok, fromModal: boolean) => {
    const [text, setText] = useState('');
    const theme = useThemeSection();
    const darkTheme = theme === 'Dark';
    const hexColor = useContext(ColorContext);
    useEffect(() => {
        setText(data.blok?.buttonText);
    }, [data]);

    const onclick = () => {
        const url = `${navigate(data)}`;
        data.blok?.buttonLink?.target === '_self' ? pushSlug(url) : window.open(url, '_blank');
    };

    if (data.blok?.gray) {
        if (data.blok.colorArrow) {
            return <UniversalButton onClick={fromModal ? undefined : onclick}>{text}</UniversalButton>;
        }
        return <GrayButton onClick={fromModal ? undefined : onclick}>{text}</GrayButton>;
    } else if (data.blok?.colorArrow) {
        if (data.color !== undefined) {
            //crosslink
            const link =
                data.blok.buttonLink?.charAt(data.blok.buttonLink.length - 1) === '/'
                    ? data.blok.buttonLink
                    : data.blok.buttonLink + '/';

            return (
                <Link href={data.blok.buttonLink ? link : ''}>
                    <ColorArrowButton
                        darkTheme={true}
                        color={'var(--' + data.color + ')'}
                        onClick={fromModal ? undefined : onclick}
                    >
                        {text}
                    </ColorArrowButton>
                </Link>
            );
        }
        return (
            <ColorArrowButton
                darkTheme={darkTheme}
                color={getColor(hexColor, darkTheme)}
                onClick={fromModal ? undefined : onclick}
            >
                {text}
            </ColorArrowButton>
        );
    }
    return (
        <ColorButton
            darkTheme={darkTheme}
            color={getColor(hexColor, darkTheme)}
            onClick={fromModal ? undefined : onclick}
        >
            {text}
        </ColorButton>
    );
};

export default Button;
